import bubble from "@/assets/sound/bubble.mp3";
import expget from "@/assets/sound/expget.mp3";
import get_token from "@/assets/sound/get_token.mp3";
import upgrade from "@/assets/sound/upgrade.mp3";
import secondmenu from "@/assets/sound/secondmenu.mp3";
import lucky_go from "@/assets/sound/lucky_go.mp3";
import win from "@/assets/sound/win.mp3";
import leisure_bgm from "@/assets/sound/leisure_bgm.mp3";
import { Howl, Howler } from "howler";

class AudioManager {
  effectEnable = false;
  musicEnable = false;
  audio = null;
  cur_bgm_id = ''
  cur_sound = null

  constructor() {
    Howler.autoUnlock = false;

    this.audio = new Audio();
    this.audio.loop = false;
  }

  /**
   * 播放音效
   * @param {*} name
   * @returns
   */
  playEffect(name,loopTime=1) {
    if (!this.effectEnable) return;

    let _loopTime = loopTime
    const sound_name = this.getSoundByName(name);
    const sound = new Howl({
      src: [sound_name],
      format: ["mp3", "wav"],
      html5: true,
      preload: true,
      loop: false,
      autoplay: true,
      onend: function () {
        if (_loopTime >1 ) {
          sound.play();
          _loopTime--
        }
      }
    });
    sound.play();

    // this.audio.src = this.getSoundByName(name);
    // this.audio.play();
  }

  /**
   * 播放音乐
   * @param {*} name
   * @returns
   */
  playMusic(name, isLoop = true, autoplay = false) {
    if (!this.musicEnable) return;

    const sound_name = this.getSoundByName(name);
    const sound = new Howl({
    src: [sound_name],
    format: ["mp3", "wav"],
    html5: true,
    preload: true,
    loop: isLoop,
    autoplay: true,

    });
    return sound
    // sound.play();

    // this.audio.src = this.getSoundByName(name);
    // this.audio.play();
  }
  /**
  * 播放背景音乐
  * @param {*} name
  * @returns
  */
  playBgmMusic(name) {
    if (!this.musicEnable) return;
    if (this.cur_bgm_id && this.cur_sound) {
      this.cur_sound.play(this.cur_bgm_id)
      return
    }

    const sound_name = this.getSoundByName(name);
    const sound = new Howl({
      src: [sound_name],
      format: ["mp3", "wav"],
      html5: true,
      preload: true,
      loop: true,
      autoplay: true,
      onplayerror: () => {
        // sound.once('unlock', function () {
        //   sound.play();
        // });
      }
    });
    this.cur_bgm_id = sound.play()
    this.cur_sound = sound
  }

  /**
   * 暂停当前音乐
   */
  pauseCurBGM() {
    if (!this.musicEnable) return;
    this.cur_sound &&this.cur_sound.pause()
  }

  /**
   * 暂停当前音乐
   */
  playCurBGM() {
    if (!this.musicEnable) return;
    this.cur_sound&&this.cur_sound.play()
  }
  /**
   * 停止当前背景音乐
   */
  stopCurBGM() {
    if (!this.musicEnable) return;
    this.cur_sound &&this.cur_sound.stop(this.cur_bgm_id)
  }



  /**
   * 卸载音乐
   */
  clearAll() {
    Howler.unload()
  }


  getSoundByName(name) {
    let sound = "";
    switch (name) {
      case "secondmenu":
        sound = secondmenu;
        break;
      case "bubble":
        sound = bubble;
        break;
      case "expget":
        sound = expget;
        break;
      case "get_token":
        sound = get_token;
        break;
      case "upgrade":
        sound = upgrade;
        break;
      case "lucky_go":
        sound = lucky_go;
        break;
      case "win":
        sound = win;
        break;
      case "leisure_bgm":
        sound = leisure_bgm;
        break;
    }
    return sound;
  }
}

export default new AudioManager();

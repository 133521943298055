import store from "../store";
import router from "@/router";
import _ from "lodash";
import audioManager from "./audioManager";

// APP JS 回调
window.callBlockoorJS = (res) => {
  const data = JSON.parse(res);
  const type = _.get(data, "type", "");
  let content = _.get(data, "content", {});
  // content 可能是 JSON字符串
  if (typeof content === "string" && content) {
    try {
      content = JSON.parse(content);
    } catch (error) {
      console.log('try to parse error: ',error);
    }
  }

  if (type === "getStatusBarHeight") {
    store.dispatch("global/statusBarHeightInit", content);
  }
  if (type === "getUserInfo") {
    store.dispatch("global/setAppUserInfo", content);
  }

  if(type==="getTokenIds"){
    store.dispatch("global/setTokenIds", content);
  }

  if(type==="getSettingLaungage"){
    store.dispatch("global/setLanguageEnv", content);
  }

};
class AppBridging {
  isIOS = false;
  isApp = false;
  isWeixin = false;
  isAndroid = false;
  headers = {
    "x-ua-device": "web", // 开发平台
    // "x-c-version": "2.0.6", // 请求头统一带上版本号
    // "x-u-pid": "", //
  };
  __headersInit = false;
  constructor() {
    this.checkEnvironment();
    this.initHeaderds();

    this.defined = this.defined.bind(this);
  }

  checkEnvironment() {
    const ua = navigator.userAgent;
    this.isIOS =
      !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || ua.indexOf("Ios") > -1;
    this.isWeixin = ua.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
    this.isAndroid = ua.indexOf("Android") > -1 || ua.indexOf("Linux") > -1; //android终端

    // this.isApp = navigator.userAgent.indexOf("Tippot") !== -1; // 安卓添加ua出错暂时隐藏
    this.isApp = this.isIOS || this.isAndroid;
  }
  
  checkIsApp() {
    return navigator.userAgent.indexOf("yjsz2020") !== -1;
  }

  /**
   * 初始化 headers
   */
  setHeaders(headers) {
    this.headers["x-ua-device"] = headers["device"];
    // this.headers["x-c-version"] = headers["x-c-version"];
    this.headers["x-cookie-id"] = headers["cookieId"];
    this.headers["x-accept-language"] = headers?.["xAcceptLanguage"] || 'en';
    //this.headers["x-city-id"] = headers.cityId;
    // this.headers["x-u-pid"] = headers["x-u-pid"];
    const effectEnable = headers['effect']
    const musicEnable = headers['music'] 
    console.log("headers",headers);
    audioManager.effectEnable = effectEnable
    audioManager.musicEnable = musicEnable
    this.__headersInit = true;
    const isAndroid = headers["AndroidsdkInt"];
    
    store.dispatch("global/globalInit", {
      init: true,
      isApp: this.isApp,
      isAndroid: isAndroid,
    });
  }

  async initHeaderds() {
    let isApp = this.isApp;

    // 如果不是APP，那么就不用了接下来的逻辑
    if (this.__headersInit) {
      return;
    }

    if (!isApp) {
      this.__headersInit = true;
      store.dispatch("global/globalInit", { init: true, isApp: this.isApp });
      return;
    }

    try {
      // 如果是安卓的话
      if (this.isAndroid) {
        let params = { type: "getHeaders", content: "", isNeedReturn: 1 };
        let res = window.BlockoorJSInterface.callNative(JSON.stringify(params));
        const data = JSON.parse(res);
        this.setHeaders(JSON.parse(data.content));
      }

      // 如果是IOS的话
      if (this.isIOS) {
        let params = { type: "getHeaders", content: "", isNeedReturn: 1 };
        window.webkit.messageHandlers.callNative.postMessage(
          JSON.stringify(params)
        );
      }
    } catch (e) {
      this.__headersInit = false;
      this.App = this.checkIsApp();
      store.dispatch("global/globalInit", { init: true, isApp: this.isApp });
    }
  }

  /**
   * 调用APP方法
   * @param {*} params
   * @param {params} type           事件类型
   * @param {params} content        内容
   * @param {params} isNeedReturn   是否需要返回
   * @returns 用于网页调用的函数
   */
  defined(params) {
    const { type, content, isNeedReturn } = params;
    let that = this;
    return new Promise(function (resolve) {
      if (!that.isApp) {
        return resolve();
      }

      if (that.isAndroid) {
        let params = type==='closeLoading'?{type}:{ type, content: JSON.stringify(content), isNeedReturn };
        let data =
          window.BlockoorJSInterface.callNative(JSON.stringify(params)) ||
          "null";

        window.callBlockoorJS(data);
        return;
      }

      // 如果是IOS的话
      if (that.isIOS) {
        let params = { type, content: JSON.stringify(content), isNeedReturn };
        window.webkit.messageHandlers.callNative.postMessage(
          JSON.stringify(params)
        );
      }
    });
  }

  /**
   * 获取状态栏
   * @returns Promise(状态栏数据)
   */
  getStatusBarHeight() {
    let that = this;
    return new Promise(function (resolve) {
      let config = {
        type: "getStatusBarHeight",
        content: "",
        isNeedReturn: 1,
      };

      that.defined(config);
    });
  }

  /**
   * 获取角色id列表
   */
  getTokenIds(){
    let that = this;
    return new Promise(function (resolve) {
      let config = {
        type: "getTokenIds",
        content: "",
        isNeedReturn: 1,
      };

      that.defined(config);
    });
  }

  /**
   * 获取语言环境
   * @return env
   */
  getSettingLaungage() {
    let that = this;
    return new Promise(function (resolve) {
      let config = {
        type: "getSettingLaungage",
        content: "",
        isNeedReturn: 1,
      };

      that.defined(config);
    });
  }

  /**
   * 关闭页面
   * @returns void
   */
  closePage() {
    let that = this;
    return new Promise(function (resolve) {
      let config = {
        type: "closePage",
        content: "",
        isNeedReturn: 1,
      };
      audioManager.clearAll()
      that.defined(config).then((res) => {
        history.go(-1);
      });
    });
  }

  /**
   * 获取角色id列表
   */
  closeLoading() {
    let that = this;
    return new Promise(function (resolve) {
      let config = {
        type: "closeLoading",
      };

      that.defined(config);
    });
  }

  /**
   * 通知移动端刷新数据
   * @returns 
   */
  handleRefreshNativeCache(apiName) {
    let that = this;
    const content = {apiName}
    return new Promise(function (resolve) {
      let config = {
        type: "refreshCache",
        content
      };

      that.defined(config);
    });
  }

  /**
   * 分享图片接口
   * @param {*} type  "download" || "share"
   * @param {*} base64Url 
   * @returns 
   */
  handleShareImage(type, base64Url){
    let that = this;
    const content = { type, base64Url };
    return new Promise(function (resolve) {
      let config = {
        type: "shareImage",
        content
      };

      that.defined(config);
    });
  }

  getUserInfo(){
    let that = this;
    const content = { };
    return new Promise(function (resolve) {
      let config = {
        type: "getUserInfo",
        content
      };

      that.defined(config);
    });
  }

  /**
   * 调用JS回调
   * @returns data 数据
   */
  // callBlockoorHeaders() {
  //   return new Promise(function (resolve) {
  //     window.callBlockoorHeaders = function (data) {
  //       return resolve(data);
  //     };
  //   });
  // }

  /**
   * 退出登录
   */
  toLogin() {
    let that = this;
    return new Promise(function (resolve) {
      let config = {
        type: "logout",
        content: "",
        isNeedReturn: 0,
      };

      that.defined(config).then((res) => {
        store.dispatch("user/tokenExpire");
        setTimeout(() => {
          router.push(`/m/login?redirect=${router.currentRoute.fullPath}`);
        }, 0);
      });
    });
  }
}

const appBridging = new AppBridging();

// APP 页面恢复
window.resumeBlockoorJS = (res) => {
  store.commit("global/SET_RESUME");
};
/**
 * 接受flutterHeader
 * @param {} res
 */
window.callBlockoorHeaders = function (res) {
  const data = JSON.parse(res);
  const content = _.get(data, "content", {});
  appBridging.setHeaders(content);
};

export default appBridging;
